import { PaginationQuery, PaginationResource } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { IndexTenant, Tenant } from './types';

const tenant = jsonApi('tenant');

const current = (): Promise<Tenant> => {
  return tenant.get('current');
};

const index = (query?: PaginationQuery): Promise<PaginationResource<IndexTenant>> => {
  return tenant.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

export const tenantApi = {
  name: 'tenant',
  current,
  index,
  view: generateResourceViewApi('tenant'),
};
