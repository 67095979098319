import type { Column } from '@libero/api-client/column/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import type { ColumnQuery, ResourceViewApi } from '@libero/api-client/types/resource-api';
import { jsonApi } from '@libero/utilities/api-client';
import { filterViewRequest } from '@libero/utilities/view';
import QueryString from 'qs';

import type {
  StoreViewRequest,
  StoreViewShareRequest,
  UpdateViewRequest,
  View,
  ViewShareItems,
  ViewTab,
} from './types';

const view = jsonApi('view');

export const generateResourceViewApi = (resourceName: string): ResourceViewApi => {
  const resourceView = jsonApi(`${resourceName}/view`);

  const index = (): Promise<View[]> => {
    return resourceView.get();
  };

  const def = (): Promise<View> => {
    return resourceView.get('default');
  };

  const empty = (): Promise<View> => {
    return resourceView.get('empty');
  };

  const tabs = (): Promise<ViewTab[]> => {
    return resourceView.get('tabs');
  };

  const availableColumns = (query: ColumnQuery): Promise<Column[]> => {
    return resourceView.get('available-columns', {
      searchParams: QueryString.stringify(query),
    });
  };

  return {
    index,
    default: def,
    empty,
    tabs,
    availableColumns,
  };
};

const index = (query?: PaginationQuery): Promise<PaginationResource<View>> => {
  return view.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<View> => {
  return view.get(id.toString());
};

const store = (data: StoreViewRequest): Promise<View> => {
  return view.post(undefined, filterViewRequest(data));
};

const update =
  (id: string | number) =>
  (data: UpdateViewRequest): Promise<unknown> => {
    return view.put(id.toString(), filterViewRequest(data));
  };

const share =
  (id: string | number) =>
  (data: StoreViewShareRequest): Promise<View> => {
    return view.put(`${id}/share`, data);
  };

const shareItems = (query?: PaginationQuery): Promise<ViewShareItems> => {
  return view.get('share/items', {
    searchParams: QueryString.stringify(query),
  });
};

const addTab = (id: string | number): Promise<unknown> => {
  return view.put(`${id}/tab/add`);
};

const removeTab = (id: string | number): Promise<unknown> => {
  return view.put(`${id}/tab/remove`);
};

const destroy = (id: string | number): Promise<unknown> => {
  return view.delete(id.toString());
};

const restore = (id: string | number): Promise<unknown> => {
  return view.patch(`${id}/restore`);
};

const favorite = (id: string | number): Promise<unknown> => {
  return view.post(`${id}/favorite`);
};

export const viewApi = {
  name: 'view',
  index,
  show,
  store,
  update,
  share,
  shareItems,
  addTab,
  removeTab,
  destroy,
  restore,
  favorite,
  view: generateResourceViewApi('view'),
  scout: generateResourceScoutApi<View>('view', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
