<script setup lang="ts">
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Container from '@libero/ui-framework/Container/Container.vue';
import SecondaryNav from '@libero/ui-framework/SecondaryNav/SecondaryNav.vue';
import SecondaryNavItem from '@libero/ui-framework/SecondaryNav/SecondaryNavItem.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { hasPermission } = useUserStore();
</script>

<template>
  <Container hasOffset>
    <Cluster flex="1" alignItems="stretch">
      <SecondaryNav hasOffset>
        <Stack>
          <Stack :gap="0">
            <Typography class="navigation__title" size="sm" mb="050" uppercase semiBold>
              {{ t('general') }}
            </Typography>

            <SecondaryNavItem
              :to="{ name: 'entity-type.index' }"
              :isDisabled="!hasPermission('entity-type.index')"
            >
              {{ t('entity-type.index') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'attribute-type.index' }"
              :isDisabled="!hasPermission('attribute-type.index')"
            >
              {{ t('attribute-type.index') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'product.index' }"
              :isDisabled="!hasPermission('product.index')"
            >
              {{ t('product.index') }}
            </SecondaryNavItem>
          </Stack>

          <Stack :gap="0">
            <Typography class="navigation__title" size="sm" mb="050" uppercase semiBold>
              {{ t('system-settings.public-lighting') }}
            </Typography>

            <SecondaryNavItem
              :to="{ name: 'power-schedule.index' }"
              :isDisabled="!hasPermission('power-schedule.index')"
            >
              {{ t('power-schedule.power-schedules') }}
            </SecondaryNavItem>

            <SecondaryNavItem
              :to="{ name: 'dim-calendar.index' }"
              :isDisabled="!hasPermission('dim-calendar.index')"
            >
              {{ t('dim-calendar.dim-calendars') }}
            </SecondaryNavItem>
          </Stack>
        </Stack>
      </SecondaryNav>

      <Stack width="100%">
        <RouterView />
      </Stack>
    </Cluster>
  </Container>
</template>
