import { RouteRecordRaw } from 'vue-router';

import DataSettingsLayout from './views/layouts/DataSettingsLayout/DataSettingsLayout.vue';

export const dataSettingsModules = [
  'entity-type',
  'attribute-type',
  'product',
  'power-schedule',
  'dim-calendar',
];

const children = Object.values(
  import.meta.glob(
    `../(entity-type|attribute-type|product|power-schedule|dim-calendar)/routes.ts`,
    {
      eager: true,
    },
  ) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

export const routes: RouteRecordRaw[] = [
  {
    path: '/data-settings',
    component: DataSettingsLayout,
    children: [
      ...children,
      {
        path: '',
        redirect: { name: 'entity-type.index' },
      },
    ],
  },
];
